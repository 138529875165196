import { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';

import { getMigrationState } from '@pages/migration-reports/helper';

import ReturnHeader from '@components/molecules/return-header';
import { HubSkeleton } from '@components/molecules/skeleton/hub-skeleton';
import ExportButton from '@components/molecules/button/export-button';
import UnitProgress from '@components/organisms/migrations/migration-unit-progress-card';
import Layout from '@components/templates/screen-layout';

import { useGetMigrationReportByGroup } from '@hooks/migration-report/use-migration-report-by-group';
import { IUnitsReport, MigrationState } from '@hooks/migration-report/use-migration-report-by-group/types';
import { useUserInfo } from '@hooks/use-user-info';
import { useIsMobile } from '@hooks/is-mobile';

import { HOME_PATH, MIGRATION_REPORT_PATH } from '@routers/constants';

import { formatDateFromString } from '@utils/dayjs';

import style from './style.module.css';

const BREADCRUMBS = [
  {
    label: 'Home',
    url: HOME_PATH,
  },
  {
    label: 'Migração para o Mercado Livre',
    url: '',
  },
];

const mapPageTitle = {
  [MigrationState.IN_PROGRESS]: 'Unidades em Migração',
  [MigrationState.OVERDUE]: 'Unidades Atrasadas',
  [MigrationState.DONE]: 'Unidades Migradas',
};

const getUnitsByState = (units: IUnitsReport[], targetState: MigrationState) => {
  // TODO: use unit.status instead of getMigrationState
  return units.filter((unit) => getMigrationState(unit.conclusionPercentage, unit.dueDate) === targetState);
};

const UnitsByState = () => {
  const exportRef = useRef<HTMLDivElement>(null);

  const { user, isFetchingUser } = useUserInfo();
  const {
    getMigrationReportByGroupHandler,
    migrationReportByGroup,
    loading: loadingMigrationReports,
  } = useGetMigrationReportByGroup();

  const navigate = useNavigate();
  const state = useParams().state;

  const isMobile = useIsMobile();

  useEffect(() => {
    if (Object.values(MigrationState).indexOf(state as MigrationState) === -1) {
      navigate(MIGRATION_REPORT_PATH);
    }
  }, []);

  useMemo(() => {
    if (user.group?.id) {
      getMigrationReportByGroupHandler(user.group.id);
    }
  }, [user]);

  if (loadingMigrationReports || isFetchingUser) {
    return (
      <Layout.Content
        header={{
          title: 'Migração para o Mercado Livre',
          displayDesktop: true,
          breadCrumbLinks: BREADCRUMBS,
        }}
      >
        <HubSkeleton />
      </Layout.Content>
    );
  }

  return (
    <Layout.Content
      header={{
        title: isMobile ? 'Migração' : mapPageTitle?.[state as MigrationState],
        displayDesktop: true,
        additionalButtons: (
          <ExportButton className="mr-2" exportRef={exportRef} exportName={`relatorio-de-migração-${state}`} />
        ),
        breadCrumbLinks: BREADCRUMBS,
      }}
    >
      <div className="w-full" ref={exportRef}>
        <div>
          <ReturnHeader returnPath={MIGRATION_REPORT_PATH} />
        </div>

        <div className={style.unitsContainer}>
          {getUnitsByState(migrationReportByGroup.units || [], state as MigrationState).map((unit: IUnitsReport) => (
            <UnitProgress
              key={unit.thirdPartyId}
              state={getMigrationState(unit.conclusionPercentage, unit.dueDate)}
              dueDate={unit.dueDate && formatDateFromString(unit.dueDate, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY')}
              progress={unit.conclusionPercentage * 100}
              name={unit.nickname}
              groupName={migrationReportByGroup.commercialGroup}
              stepsInfo={{
                total: unit.topics.length,
                completed: unit.topics.filter((topic) => topic.conclusionPercentage === 1).length,
              }}
              viewClick={() => {
                eventTracker.trackEvent(eventLabels.CARD_SEEMORE_UC_MIGRATION);
                navigate(`${MIGRATION_REPORT_PATH}/${unit.thirdPartyId}`);
              }}
            />
          ))}

          {getUnitsByState(migrationReportByGroup.units || [], state as MigrationState).length === 0 && (
            <div className={style.noUnitsFound}>
              <p>Nenhuma unidade encontrada</p>
            </div>
          )}
        </div>
      </div>
    </Layout.Content>
  );
};

export default UnitsByState;
