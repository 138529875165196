import React, { useMemo, useState } from 'react';

import { CONTRACTS_PATH, HOME_PATH } from '@routers/constants';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';

import { useUserInfo } from '@hooks/use-user-info';
import { Contract } from '@hooks/get-contracts/types';
import { useContractByGroup } from '@hooks/get-contracts/use-contracts-group';
import { useConsumptionReportsByGroup } from '@hooks/get-consumption-contracts/use-consumption-contracts-group';

import Layout from '@components/templates/screen-layout';
import EmptyData from '@components/molecules/empty-data';
import ContractCardsSection from '@components/organisms/contracts/card';
import ChartsSection from '@components/organisms/contracts/charts/charts';
import { HubSkeleton } from '@components/molecules/skeleton/hub-skeleton';
import ContractModal, { ContractModalProps } from '@components/organisms/contracts/modal';
import TitleFilter, { ContractFilters } from '@components/molecules/contract/title-filter';

import style from './style.module.css';

const Contracts: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [targetContract, setTargetContract] = useState<Contract | null>(null);
  const [filter, setFilter] = useState<ContractFilters>('ALL');
  const [chartData, setChartData] = useState<ContractModalProps['consumptionChartData']>();

  const { user } = useUserInfo();
  const { getContractByGroup, data, error, loading } = useContractByGroup();
  const { getConsumptionsReportsHandler, loadingConsumptions } = useConsumptionReportsByGroup();

  const contracts: Contract[] = useMemo(() => {
    return data.energyContracts.concat(data.managementContracts);
  }, [data]);

  const emptyDataContent = !contracts.length || error;

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.PAGE_VIEW_CONTRACTS);
  }, []);

  React.useEffect(() => {
    if (user.group?.id) {
      const groupId = user.group?.id;

      getContractByGroup({
        variables: {
          groupId,
        },
      });
    }
  }, [user]);

  const filteredContracts: Contract[] = React.useMemo(() => {
    if (filter === 'ALL') return contracts;

    return contracts.filter(({ type }) => type === filter);
  }, [contracts, filter]);

  if (loading) {
    return (
      <Layout.Content
        header={{
          title: '',
          displayDesktop: true,
        }}
      >
        <HubSkeleton />
      </Layout.Content>
    );
  }

  return (
    <Layout.Content
      extraContentClassName={emptyDataContent ? 'h-screen' : 'h-full'}
      header={{
        title: 'Contratos',
        displayDesktop: true,
        breadCrumbLinks: [
          {
            label: 'Home',
            url: HOME_PATH,
          },
          {
            label: 'Contratos',
            url: CONTRACTS_PATH,
          },
        ],
      }}
    >
      {emptyDataContent ? (
        <EmptyData
          description="Ao contratar energia, seus contratos estarão disponíveis aqui para consulta."
          title="Você ainda não possui contratos de energia."
        />
      ) : (
        <div className={style.contentContainer}>
          <ChartsSection contracts={data.energyContracts} />

          <div className={style.contractFilterContainer}>
            <hr />

            <TitleFilter
              title="Contratos"
              onChange={(e: string) => {
                eventTracker.trackEvent(eventLabels.BUTTON_FILTER_CONTRACTS);
                setFilter(e as ContractFilters);
              }}
              value={filter}
            />
          </div>

          {!filteredContracts.length && (
            <div className={style.filterEmpty}>
              <EmptyData
                className="h-fit"
                title="Nenhum contrato encontrado"
                description="Não foi possivel filtrar nenhum contrato desse tipo."
              />
            </div>
          )}

          {filteredContracts.map((contract, idx) => {
            return (
              <ContractCardsSection
                key={`card-${contract.contracted}-${idx}`}
                {...contract}
                onViewClick={() => {
                  eventTracker.trackEvent(eventLabels.BUTTON_SEE_MORE_CONTRACTS);
                  setTargetContract(contract);

                  if (contract.type === 'ENERGY') {
                    getConsumptionsReportsHandler(contract.unitsIds).then((response) => {
                      const data = response ?? [];
                      setChartData({
                        consumptions: data,
                        flexPerYear: contract.flexPerYear,
                        volumePerYear: contract.volumePerYear,
                        isStandby: contract?.isStandby ?? false,
                      });
                    });
                  }

                  setModalOpen(!modalOpen);
                }}
              />
            );
          })}

          {targetContract && (
            <ContractModal
              cardType={targetContract.type}
              active={targetContract.active}
              isStandby={targetContract?.isStandby ?? false}
              content={{ ...targetContract }}
              consumptionChartData={chartData}
              loadingChart={loadingConsumptions}
              isOpen={modalOpen}
              setIsOpen={setModalOpen}
            />
          )}
        </div>
      )}
    </Layout.Content>
  );
};

export default Contracts;
