import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuMobile, MenuProps } from '@clarke-energia/foton-v2';

import { HOME_PATH } from '@routers/constants';
import { useGetGroups } from '@hooks/get-group/get-groups';
import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserInfo } from '@hooks/use-user-info';
import { useMenuItemActive } from '@hooks/menu-item-active';
import { InputOptionProps } from '@utils/types';

import { BASE_MENU_ITENS } from '../sidebar/helper';

import style from './style.module.css';

const SidebarMobile: React.FC = () => {
  const { user, upsertGroupRelationWithUserHandler, isUpsertRelationSuccess, groupsByUser } = useUserInfo();
  const { logout } = useAuth0();
  const { data, loading } = useGetGroups();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [inputOptions, setInputOptions] = useState<InputOptionProps[]>([]);

  const menuItems = BASE_MENU_ITENS.map((section) => ({
    ...section,
    links: section.links.map((item) => ({
      ...item,
      isActive: useMenuItemActive(item.path),
      LinkComponent: Link,
    })),
  })) as MenuProps['menuItem'];

  const onGroupSelected = (groupId: string) => {
    eventTracker.trackEvent(eventLabels.BUTTON_CHANGE_COMPANY_MENU);
    upsertGroupRelationWithUserHandler(groupId);
  };

  useEffect(() => {
    if (isUpsertRelationSuccess) {
      window.location.replace(HOME_PATH);
    }
  }, [isUpsertRelationSuccess]);

  useEffect(() => {
    if (user) {
      if (data?.groups.data || groupsByUser) {
        const localGroups = user.isSuperAdmin ? data?.groups.data : groupsByUser;
        const localInputOptions = localGroups
          ? localGroups.map((group) => {
              return {
                label: group.name,
                value: group.id,
              };
            })
          : [];
        setInputOptions(localInputOptions);
      }
    }
  }, [data?.groups.data, groupsByUser, user]);

  useEffect(() => {
    if (isMenuOpen) {
      eventTracker.trackEvent(eventLabels.BUTTON_EXPAND_MENU);
    } else {
      eventTracker.trackEvent(eventLabels.BUTTON_LEAVE_MENU);
    }
  }, [isMenuOpen]);

  const sortedGroups = useMemo(() => {
    return inputOptions.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }, [inputOptions]);

  return (
    <div className={style.container}>
      <MenuMobile
        handleLogout={logout}
        userInfo={{
          name: user.name,
          email: user.email,
          picture: { src: user?.photoUrl },
        }}
        menuItem={menuItems}
        setIsOpen={setIsMenuOpen}
        isOpen={isMenuOpen}
        groupListSelect={{
          options: sortedGroups,
          value: user.group?.id,
          onChange: (value: string) => onGroupSelected(value),
          placeholder: loading ? 'Carregando...' : 'Selecione o grupo',
          container: {
            content: {
              className: 'max-w-96',
            },
          },
        }}
      />
    </div>
  );
};

export default SidebarMobile;
