import React from 'react';
import { twMerge } from 'tailwind-merge';
import { BreakpointsSize, hooks } from '@clarke-energia/foton-v2';
import { EconomyChartProps } from './types';

import style from './style.module.css';

const { useBreakpoints } = hooks;
const mapFontSize = {
  [BreakpointsSize.SM]: 75,
  [BreakpointsSize.MD]: 120,
  [BreakpointsSize.LG]: 95,
  [BreakpointsSize.XL]: 85,
  [BreakpointsSize.XXL]: 110,
};

const EconomyChart: React.FC<EconomyChartProps> = ({
  freeMarket,
  conventionalMarket,
  economy,
  economyPrefix = '%',
  height = '250px',
  ...props
}) => {
  const { size } = useBreakpoints();
  let baseFontSize = mapFontSize[size as BreakpointsSize];
  if (window.innerWidth >= 1800) {
    baseFontSize = 140;
  }
  const fontSize = baseFontSize - conventionalMarket.value.length / 60;

  return (
    <div {...props}>
      <div className={style.mainContainer}>
        <div className={style.economyValue}>{economy + economyPrefix}</div>
        <div
          className={style.innerContainer}
          style={{
            height: height,
          }}
        >
          <div className={twMerge(style.bar, style.mainBar, conventionalMarket.color)}>
            <p
              className={style.marketValue}
              style={{
                fontSize: `${fontSize}` + '%',
              }}
            >
              {conventionalMarket.value}
            </p>
            <p className={style.marketTitle}>{conventionalMarket.title}</p>
          </div>
          <div
            className={twMerge(style.bar, style.secondaryBar, freeMarket.color)}
            style={{
              transition: 'all 0.25s linear',
              height: `calc(100% - ${economy}%)`,
            }}
          >
            <p
              className={style.marketValue}
              style={{
                fontSize: `${fontSize}` + '%',
              }}
            >
              {freeMarket.value}
            </p>
            <p className={style.marketTitle}>{freeMarket.title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EconomyChart;
