import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SelectOption } from '@clarke-energia/foton-v2';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';
import { HOME_PATH, ECONOMY_REPORT_PATH as REPORT_PATH } from '@routers/constants';

import { colors } from '@utils/theme';

import { useUserInfo } from '@hooks/use-user-info';
import { useDownloadFiles } from '@hooks/download-files';

import {
  convertFromAbbrMonthNameAndYearToYearMonth,
  formatDateToAbbrMonthNameAndYear,
  formatDateToMonthNameAndYear,
} from '@utils/dayjs';

import { useGetGroupAccumulatedEconomyReport } from '@hooks/economy/get-group-accumulated-economy-report';

import { useExportElementAsImage } from '@hooks/export-as-image';
import { useAuthorizationHeaders } from '@hooks/use-authorization-headers';
import { useGetUnitEconomyByGroupId } from '@hooks/economy/get-unit-economy-by-group-id';
import {
  IGeneralReportTable,
  IGeneralReportTableData,
  IMinimalGeneralReportTable,
  UnitEconomyReportDataResponse,
} from '@hooks/economy/types';

import Layout from '@components/templates/screen-layout';
import EmptyData from '@components/molecules/empty-data';
import PageSection from '@components/molecules/general/page-section';
import { EconomyReportSkeleton } from '@components/molecules/skeleton/economy-report-skeleton';
import ExportButton from '@components/molecules/button/export-button';

import EconomyFilters from '@components/organisms/economy-report/filter';
import EconomyCards from '@components/organisms/economy-report/economy-cards';
import EconomyBarCharts from '@components/organisms/economy-report/economy-charts';
import UnitEconomyCards from '@components/organisms/economy-report/unit-economy-cards';
import EconomyComparison from '@components/organisms/economy-report/economy-comparison';
import ReturnHeader from '@components/molecules/return-header';

import configuration from '@config';

import {
  buildEconomyCardDataFromGroupAccumulatedReport,
  buildEconomyCardDataFromSummary,
  buildEconomyCardDataFromUnitEconomyReport,
  buildEconomyCardDataFromUnitReport,
  getUnitEconomyCardsData,
} from './helpers/economy-cards';

import {
  buildEconomyChartDataFromAllHistory,
  buildEconomyChartDataFromUnitEconomyReport,
  buildEconomyChartDataFromUnitId,
  buildEconomyChartDataFromUnitReports,
} from './helpers/economy-chart';

import {
  Unit,
  EconomyFilterState,
  UnitSummaryEconomyReport,
  EconomyReportIconCardData,
  EconomyChartData,
  CostChartData,
} from './types';

import {
  buildUnitFilter,
  defaultUnitFilter,
  buildPeriodFilter,
  getUnitsFromReport,
  defaultPeriodFilter,
  getFreeMarketDataTable,
  getConventionalMarketDataTable,
  getUnitEconomyReportByIdAndPeriod,
  getUnitAccumulatedEconomyReportById,
  getGroupAccumulatedEconomyReportByPeriod,
  buildCostChartData,
} from './functions';

import style from './style.module.css';

const EconomyReport: React.FC = () => {
  const navigate = useNavigate();
  const { isFetchingUser } = useUserInfo();
  const [searchParams] = useSearchParams();
  const { id, period } = useParams();

  let returnPath = '';
  if (id) {
    returnPath = REPORT_PATH;
  }

  const exportRef = useRef<HTMLDivElement>(null);

  const unitId = searchParams.get('unitId');
  const date = searchParams.get('date');

  const unitParam = id || unitId;
  const periodParam = period || date;

  const { user } = useUserInfo();

  const ECONOMY_REPORT_PATH = configuration.REPORT_API_HOST + '/api/v1/economy_report_excel';
  const ECONOMY_COMPARISON_PATH = configuration.REPORT_API_HOST + '/api/v1/economy_comparison_report_excel';

  const headersWithAuthorization = useAuthorizationHeaders();

  const handleRedirect = (unitId: string, date?: string) => {
    if (unitId && date) return navigate(`${REPORT_PATH}/${unitId}/${encodeURIComponent(date)}`);

    navigate(`${REPORT_PATH}/${unitId}`);
  };

  const {
    getUnitsEconomyReportsByGroupId,
    data: { unitsEconomyReports, rawHistoryUnitEconomyReport },
    loading: getUnitsEconomyReportsByGroupIdLoading,
  } = useGetUnitEconomyByGroupId();

  const {
    getGroupAccumulatedEconomyReportByGroupId,
    getGroupAccumulatedEconomyReportByUnitId,
    parsedData: { parsedGroupAccumulatedEconomyReport, parsedUnitAccumulatedSummaryReport },
  } = useGetGroupAccumulatedEconomyReport();

  const { downloadExcelFile } = useDownloadFiles();
  const exportAsImage = useExportElementAsImage();

  const [units, setUnits] = useState<Unit[]>([]);
  const [filter, setFilter] = useState<EconomyFilterState>({
    unit: unitParam,
    period: periodParam ? formatDateToMonthNameAndYear(periodParam, 'YYYY-MM-DD') : undefined,
  } as EconomyFilterState);

  const [filterUnits, setFilterUnits] = useState<SelectOption[]>([defaultUnitFilter]);
  const [filterPeriods, setFilterPeriods] = useState<SelectOption[]>([defaultPeriodFilter]);

  const [showEconomyComparison, setShowEconomyComparison] = useState<boolean>(false);

  const [selectedReport, setSelectedReport] = useState<UnitEconomyReportDataResponse | null>(null);

  const [freeMarketTables, setFreeMarketTables] =
    useState<Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>>();
  const [conventionalMarketTables, setConventionalMarketTables] =
    useState<Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>>();

  const [unitsEconomyData, setUnitsEconomyData] = useState<UnitSummaryEconomyReport[]>([]);
  const [economyDataCardsData, setEconomyDataCardsData] = useState<EconomyReportIconCardData | null>(null);

  const [costChartData, setCostChartData] = useState<CostChartData>({
    title: 'Custo de Energia',
    freeMarket: {
      name: 'Custo no Mercado Livre',
      data: [],
      color: colors.primary[1],
    },
    conventionalMarket: {
      name: 'Custo no Mercado Cativo',
      data: [],
      color: colors.gray[10],
    },
    economy: {
      name: 'Economia',
      data: [],
      lineStyle: {
        type: 'solid',
        width: 3,
      },
      color: colors.primary[3],
    },
    labels: [],
  });
  const [economyChartData, setEconomyChartData] = useState<EconomyChartData | null>(null);

  const costChartRef = useRef<HTMLDivElement>(null);
  const economyChartRef = useRef<HTMLDivElement>(null);
  const economyComparisonRef = useRef<HTMLDivElement>(null);

  const hasData = useMemo(() => {
    return unitsEconomyReports && Object.keys(unitsEconomyReports).length > 0;
  }, [unitsEconomyReports]);

  const isValidFilterPeriodButAll = useMemo(() => {
    return filter.period && filter.period != 'Todo';
  }, [filter]);

  const isValidFilterUnitButAll = useMemo(() => {
    return filter.unit && filter.unit != "Todas as UC's";
  }, [filter]);

  const getUnitsEconomyData = useMemo(() => {
    const reports = isValidFilterPeriodButAll
      ? unitsEconomyReports[filter.period ?? '']
      : parsedUnitAccumulatedSummaryReport;
    return getUnitEconomyCardsData(reports);
  }, [isValidFilterPeriodButAll, filter, unitsEconomyReports, parsedUnitAccumulatedSummaryReport]);

  const getCostChartData = () => {
    const newCostChartData = { ...costChartData };

    const chartData = buildCostChartData(unitsEconomyReports, rawHistoryUnitEconomyReport, filter.unit);

    newCostChartData.economy.data = chartData.economy;
    newCostChartData.freeMarket.data = chartData.freeMarket;
    newCostChartData.conventionalMarket.data = chartData.conventionalMarket;
    newCostChartData.labels = Object.keys(unitsEconomyReports).map((e) => {
      const date = unitsEconomyReports[e][0].date;
      return formatDateToAbbrMonthNameAndYear(date);
    });

    setCostChartData(newCostChartData);
  };

  const exportCharts = (type: 'economy' | 'cost') => {
    const element = type == 'economy' ? economyChartRef.current : costChartRef.current;

    const trigger = element?.querySelector('button');
    trigger?.classList.add('hidden');

    exportAsImage({
      element: element as HTMLElement,
      exportName: type == 'economy' ? 'gráfico-economia' : 'gráfico-custo-energia',
    });
    trigger?.classList.remove('hidden');
  };

  useEffect(() => {
    if (user?.group) {
      if (user.group.units.length === 1) {
        const unitId = user.group.units[0].id;

        getGroupAccumulatedEconomyReportByUnitId({
          variables: {
            unitId: unitId,
          },
        });
      } else {
        getGroupAccumulatedEconomyReportByGroupId({
          variables: {
            groupId: user.group.id,
          },
        });
      }

      getUnitsEconomyReportsByGroupId({
        variables: {
          id: user.group.id,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (!getUnitsEconomyReportsByGroupIdLoading) {
      setUnits(getUnitsFromReport(rawHistoryUnitEconomyReport));

      setFilterPeriods(buildPeriodFilter(unitsEconomyReports));

      setEconomyChartData(buildEconomyChartDataFromAllHistory(rawHistoryUnitEconomyReport));
      setEconomyDataCardsData(buildEconomyCardDataFromGroupAccumulatedReport(parsedGroupAccumulatedEconomyReport));

      setUnitsEconomyData(getUnitsEconomyData);
      getCostChartData();
    }
  }, [getUnitsEconomyReportsByGroupIdLoading]);

  useEffect(() => {
    setFilter((prev) => {
      return { ...prev, unit: unitParam };
    });
  }, [unitParam]);

  useEffect(() => {
    if (!filter.unit) {
      eventTracker.trackEvent(eventLabels.PAGE_VIEW_ECONOMY_REPORT);
    } else {
      eventTracker.trackEvent(eventLabels.PAGE_VIEW_ECONOMY_REPORT_UC);
    }
  }, [filter.unit]);

  useEffect(() => {
    if (filter.unit && filter.period) {
      eventTracker.trackEvent(eventLabels.PAGE_VIEW_ECONOMY_REPORT_UC_MONTH);
      eventTracker.trackEvent(eventLabels.BUTTON_FILTER_DATE_ECONOMY_REPORT_UC);
    } else {
      eventTracker.trackEvent(eventLabels.BUTTON_FILTER_DATE_ECONOMY_REPORT);
    }
  }, [filter]);

  useEffect(() => {
    setFilterUnits(buildUnitFilter(units));
  }, [units]);

  useEffect(() => {
    if (selectedReport) {
      setFreeMarketTables(getFreeMarketDataTable(selectedReport));
      setConventionalMarketTables(getConventionalMarketDataTable(selectedReport));
    } else {
      setFreeMarketTables([]);
      setConventionalMarketTables([]);
    }
  }, [selectedReport]);

  useEffect(() => {
    setUnitsEconomyData(getUnitsEconomyData);

    setShowEconomyComparison(false);

    if (isValidFilterPeriodButAll && isValidFilterUnitButAll) {
      const reportByUnitPeriod = getUnitEconomyReportByIdAndPeriod(
        filter.unit ?? '',
        filter.period ?? '',
        unitsEconomyReports,
        rawHistoryUnitEconomyReport,
      );

      if (reportByUnitPeriod) {
        setEconomyChartData(buildEconomyChartDataFromUnitEconomyReport(reportByUnitPeriod));
        setSelectedReport(reportByUnitPeriod);
        setEconomyDataCardsData(buildEconomyCardDataFromUnitEconomyReport(reportByUnitPeriod));
      }

      setShowEconomyComparison(true);
    } else if (isValidFilterUnitButAll) {
      const unitAccumulatedReport = getUnitAccumulatedEconomyReportById(
        filter.unit ?? '',
        parsedUnitAccumulatedSummaryReport,
      );

      if (unitAccumulatedReport) {
        setEconomyChartData(buildEconomyChartDataFromUnitId(filter.unit ?? '', rawHistoryUnitEconomyReport));
        setEconomyDataCardsData(buildEconomyCardDataFromUnitReport(unitAccumulatedReport));
      }

      getCostChartData();
    } else if (isValidFilterPeriodButAll) {
      const reportByPeriod = getGroupAccumulatedEconomyReportByPeriod(
        filter.period ?? '',
        parsedGroupAccumulatedEconomyReport,
      );

      if (reportByPeriod) {
        setEconomyChartData(
          buildEconomyChartDataFromUnitReports(unitsEconomyReports[filter.period ?? ''], rawHistoryUnitEconomyReport),
        );
        setEconomyDataCardsData(buildEconomyCardDataFromSummary(reportByPeriod));
      }
      getCostChartData();
    } else {
      setEconomyChartData(buildEconomyChartDataFromAllHistory(rawHistoryUnitEconomyReport));
      setEconomyDataCardsData(buildEconomyCardDataFromGroupAccumulatedReport(parsedGroupAccumulatedEconomyReport));

      getCostChartData();
    }
  }, [filter, getUnitsEconomyReportsByGroupIdLoading]);

  const exportEconomyComparison = (value: string) => {
    eventTracker.trackEvent(eventLabels.BUTTON_EXPORT_COMPARISON_ECONOMY_REPORT_UC_MONTH);

    if (value == 'PNG') {
      exportAsImage({
        element: economyComparisonRef.current as HTMLElement,
        exportName: 'relatorio-comparativo-economia',
      });
    } else if (value == 'EXCEL') {
      // @ts-expect-error period is a valid value
      const period = convertFromAbbrMonthNameAndYearToYearMonth(filter.period);
      downloadExcelFile(
        `${ECONOMY_COMPARISON_PATH}/${filter.unit}/${period}`,
        'comparacao-economia',
        headersWithAuthorization,
      );
    }
  };

  if (isFetchingUser) {
    return (
      <Layout.Content
        header={{
          title: 'Relatório de economia',
          displayDesktop: true,
        }}
      >
        <EconomyReportSkeleton />
      </Layout.Content>
    );
  }

  return (
    <Layout.Content
      extraContentClassName={!hasData && !getUnitsEconomyReportsByGroupIdLoading ? 'h-screen' : 'h-full'}
      header={{
        title: 'Relatório de economia',
        displayDesktop: true,
        breadCrumbLinks: [
          {
            label: 'Home',
            url: HOME_PATH,
          },
          {
            label: 'Relatório de economia',
            url: '#',
          },
        ],
        children: (
          <ExportButton
            exportRef={exportRef}
            exportName="relatorio-de-economia"
            className="mr-2"
            eventTrackerLabel="BUTTON_EXPORT_ECONOMY_REPORT"
          />
        ),
      }}
    >
      {getUnitsEconomyReportsByGroupIdLoading ? (
        <EconomyReportSkeleton />
      ) : !hasData && !getUnitsEconomyReportsByGroupIdLoading ? (
        <EmptyData description="A migração para o Mercado Livre ainda não foi concluída. Você encontrará os relatórios de economia assim que concluir o processo de migração." />
      ) : (
        <div ref={exportRef}>
          <div className={style.filtersContainer}>
            {returnPath ? (
              <div className="w-[100px]">
                <ReturnHeader returnPath={returnPath} />
              </div>
            ) : null}
            <div className={!returnPath ? 'ml-auto' : ''}>
              <EconomyFilters
                units={{
                  options: filterUnits,
                  onChange: (unit) => {
                    setFilter((prev) => {
                      return { ...prev, unit: unit == "Todas as UC's" ? null : unit };
                    });
                    if (unit !== "Todas as UC's") {
                      eventTracker.trackEvent(eventLabels.BUTTON_FILTER_UC_ECONOMY_REPORT);
                    }
                  },
                  value: filter.unit ?? "Todas as UC's",
                  emptyValue: "Todas as UC's",
                }}
                periods={{
                  options: filterPeriods,
                  onChange: (period) => {
                    setFilter((prev) => {
                      return { ...prev, period: period == 'Todo' ? null : period };
                    });
                  },
                  value: filter.period ?? 'Todo',
                  emptyValue: 'Todo',
                }}
              />
            </div>
          </div>

          <div className={'grid grid-cols-3 col-span-full gap-8'}>
            {economyDataCardsData && (
              <PageSection>
                <EconomyCards className={style.container} economyReportIconCardData={economyDataCardsData} />
              </PageSection>
            )}

            <PageSection>
              <EconomyBarCharts
                id="economy-cost-charts-container"
                className={style.container}
                economyChart={{
                  ref: economyChartRef,
                  title: 'Economia',
                  freeMarket: {
                    title: 'Custo no Mercado Livre',
                    value: economyChartData?.freeMarket ?? '',
                    color: 'bg-primary-30',
                  },
                  conventionalMarket: {
                    title: 'Custo no Mercado Cativo',
                    color: 'bg-gray-10',
                    value: economyChartData?.conventionalMarket ?? '',
                  },
                  economy: economyChartData?.economy ?? 0,
                  onChange(value) {
                    if (!filter.unit || filter.unit == "Todas as UC's") {
                      eventTracker.trackEvent(eventLabels.BUTTON_EXPORT_CHART_ECONOMY_ECONOMY_REPORT);
                    } else {
                      eventTracker.trackEvent(eventLabels.BUTTON_EXPORT_CHART_ECONOMY_ECONOMY_REPORT_UC);
                    }
                    if (value == 'PNG') {
                      exportCharts('economy');
                    }
                  },
                }}
                costChart={{
                  ref: costChartRef,
                  title: costChartData?.title ?? '',
                  lineSeries: [costChartData.economy],
                  labels: costChartData?.labels ?? [],
                  series: [costChartData?.conventionalMarket, costChartData?.freeMarket],
                  onChange(value) {
                    if (!filter.unit || filter.unit == "Todas as UC's") {
                      eventTracker.trackEvent(eventLabels.BUTTON_EXPORT_CHART_COST_ECONOMY_REPORT);
                    } else {
                      eventTracker.trackEvent(eventLabels.BUTTON_EXPORT_CHART_COST_ECONOMY_REPORT_UC);
                    }

                    if (value == 'PNG') {
                      exportCharts('cost');
                    }
                    if (value == 'EXCEL') {
                      if (!filter.unit || filter.unit == "Todas as UC's") {
                        const groupId = user?.group?.id || '';
                        downloadExcelFile(
                          `${ECONOMY_REPORT_PATH}/group/${groupId}`,
                          'relatorio-economia-grupo-comercial.xlsx',
                          headersWithAuthorization,
                        );
                      } else {
                        const unitId = filter.unit ?? '';
                        downloadExcelFile(
                          `${ECONOMY_REPORT_PATH}/unit/${unitId}`,
                          'relatorio-economia-unidade-consumidora.xlsx',
                          headersWithAuthorization,
                        );
                      }
                    }
                  },
                }}
              />
            </PageSection>

            {!filter.unit && unitsEconomyData.length > 0 && (
              <PageSection>
                <UnitEconomyCards
                  unitEconomyCardsItems={unitsEconomyData}
                  onIconClick={(id) => {
                    const period = filter.period;
                    eventTracker
                      .trackEvent(eventLabels.BUTTON_SEE_MORE_UC)
                      .then(() => handleRedirect(id, period ?? undefined))
                      .catch(() => handleRedirect(id, period ?? undefined));
                  }}
                />
              </PageSection>
            )}

            {showEconomyComparison && (
              <PageSection>
                <div onClick={() => eventTracker.trackEvent(eventLabels.ACCORDION_COMPARISON_ECONOMY_REPORT_MONTH)}>
                  <EconomyComparison
                    report={selectedReport}
                    tables={{
                      freeMarket: freeMarketTables,
                      conventionalMarket: conventionalMarketTables,
                    }}
                    content={{
                      ref: economyComparisonRef,
                    }}
                    exportComponent={{
                      onChange: exportEconomyComparison,
                    }}
                  />
                </div>
              </PageSection>
            )}
          </div>
        </div>
      )}
    </Layout.Content>
  );
};

export default EconomyReport;
