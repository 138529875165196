import config from '@src/config';
import { amplitudeInit, amplitudeTrackEvent } from './amplitude';

type UserData = {
  name?: string;
  email?: string;
  group: string;
  isSuperAdmin: 'YES' | 'NO';
};

class EventTracker {
  private initialized = false;
  private unitializedError = new Error('"initializeService" was not called.');
  private userData: UserData = {} as UserData;

  setUserData(userData: UserData) {
    this.userData = userData;
  }

  async initializeService() {
    if (this.initialized) throw new Error('Initialization already called!.');

    if (config.IS_IN_PRODUCTION) this.initialized = await amplitudeInit(true);
  }

  async trackEvent(eventLabel: string, eventProperties?: Record<string, string | number | boolean>) {
    if (!this.initialized) throw this.unitializedError;

    const combinedProperties = {
      ...eventProperties,
      ...this.userData,
    };

    if (config.IS_IN_PRODUCTION) amplitudeTrackEvent(eventLabel, combinedProperties);
  }
}

const eventTracker = new EventTracker();
export default eventTracker;
