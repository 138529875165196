import { BarChart } from '@clarke-energia/foton-v2';

import { eventLabels } from '@services/event-tracking/events-label';
import { $dayjs } from '@utils/dayjs';
import ChartContainer from '@components/molecules/charts/chart-container';
import { IDateProjection } from '@contexts/consumption';

import { MonthlyControlMeasurement } from '@hooks/consumption/use-get-unit-control-measurements-by-unit-id-and-month-year/types';

const formatDates = (allDates: string[]) =>
  allDates.map((date) =>
    $dayjs(date)
      .format('DD-ddd')
      .replace(/-(\w)/, (_, firstLetter) => `\n${firstLetter.toUpperCase()}`),
  );

const parseChartData = (measures: MonthlyControlMeasurement[], projectedMeasures: IDateProjection[]) => {
  const consolidatedMap = new Map(measures.map((measure) => [measure.date, measure.totalConsumption]));

  const projectedMap = new Map(
    projectedMeasures
      .filter((measure) => !consolidatedMap.has(measure.date))
      .map((measure) => [
        measure.date,
        typeof measure.consumptionPredicted === 'string'
          ? parseFloat(measure.consumptionPredicted)
          : measure.consumptionPredicted,
      ]),
  );

  const allDates = Array.from(new Set([...consolidatedMap.keys(), ...projectedMap.keys()])).sort();

  const consolidateData = allDates.map((date) => consolidatedMap.get(date) ?? 0);
  const projectedData = allDates.map((date) => projectedMap.get(date) ?? 0);

  return {
    labels: formatDates(allDates),
    series: [
      {
        data: consolidateData,
        name: 'Consumo realizado',
        color: '#00BF6A',
      },
      {
        data: projectedData,
        name: 'Consumo projetado',
        color: '#CFDFFC',
      },
    ],
  };
};

interface DailyConsumptionChartProps {
  title: string;
  eventTrackerLabel: keyof typeof eventLabels;
  measures: MonthlyControlMeasurement[];
  projectedMeasures?: IDateProjection[];
  exportName: string;
  excelPath?: string;
}

const DailyConsumptionChart = ({
  title,
  measures,
  projectedMeasures,
  exportName,
  excelPath,
  eventTrackerLabel,
}: DailyConsumptionChartProps) => {
  const { labels, series } = parseChartData(measures, projectedMeasures || []);

  return (
    <ChartContainer
      title={{ text: title }}
      className=""
      tooltipContent="Esse gráfico mostra o consumo realizado e projetado diário"
      eventTrackerLabel={eventTrackerLabel}
      export={{
        name: exportName,
        excelPath: excelPath,
      }}
    >
      <BarChart
        labels={labels}
        stack
        options={{
          legend: { show: true },
          autoScale: true,
          suffix: ' MWh',
        }}
        series={series}
      />
    </ChartContainer>
  );
};

export default DailyConsumptionChart;
