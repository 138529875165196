import { ButtonProps, IconName } from '@clarke-energia/foton-v2';

interface NuggetInfoData {
  icon: IconName;
  info: string;
  redirectLink: string;
  event: 'BUTTON_BIOMASSA' | 'BUTTON_SOLAR' | 'BUTTON_EOLICA' | 'BUTTON_PCH';
}
interface certificateButton {
  icon: IconName;
  label: string;
  event: 'BUTTON_DISABLED_IREC_SUSTENTABILITY' | 'BUTTON_DISABLED_CERTIFICATE_SUSTENTABILITY';
  type: ButtonProps['kind'];
}

export const sustainabilityNuggets: NuggetInfoData[] = [
  {
    icon: 'CloudIcon',
    redirectLink: 'https://blog.clarke.com.br/post/biomassa-saiba-como-e-gerada-e-principais-vantagens/',
    info: 'Biomassa',
    event: 'BUTTON_BIOMASSA',
  },
  {
    icon: 'WindTurbineIcon',
    redirectLink: 'https://blog.clarke.com.br/post/como-instalar-energia-eolica/',
    info: 'Eólica',
    event: 'BUTTON_EOLICA',
  },
  {
    icon: 'SunIcon',
    redirectLink: 'https://blog.clarke.com.br/post/energia-solar-vale-a-pena/',
    info: 'Solar',
    event: 'BUTTON_SOLAR',
  },
  {
    icon: 'RiverIcon',
    redirectLink: 'https://blog.clarke.com.br/post/pch-entenda-como-funciona/',
    info: 'PCH',
    event: 'BUTTON_PCH',
  },
];

export const certificateButtons: certificateButton[] = [
  {
    icon: 'LockClosedIcon',
    label: 'Comprar certificado I-REC',
    event: 'BUTTON_DISABLED_IREC_SUSTENTABILITY',
    type: 'secondary',
  },
  {
    icon: 'LockClosedIcon',
    label: 'Emitir certificado Clarke gratuito',
    event: 'BUTTON_DISABLED_CERTIFICATE_SUSTENTABILITY',
    type: 'primary',
  },
];
