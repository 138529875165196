import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';
import MobileLikeIconButton, { MobileLikeIconButtonProps } from '@components/atoms/buttons/mobile-like-icon-button';
import { useExportElementAsImage } from '@hooks/export-as-image';

interface ExportButtonProps extends Omit<MobileLikeIconButtonProps, 'size' | 'leftIcon' | 'onClick'> {
  exportRef: React.RefObject<HTMLElement>;
  exportName: string;
  onExportExtraClassnames?: string;
  exportBackgroundColor?: string;
  exportStyleSheet?: string;
  eventTrackerLabel?: keyof typeof eventLabels;
}

const ExportButton = (props: ExportButtonProps) => {
  const exportElementAsImage = useExportElementAsImage();

  return (
    <MobileLikeIconButton
      {...props}
      size="medium"
      leftIcon="ArrowDownTrayIcon"
      onClick={async () => {
        if (props.eventTrackerLabel) {
          eventTracker.trackEvent(eventLabels[props.eventTrackerLabel]);
        }
        if (!props.exportRef.current) return;
        await exportElementAsImage({
          element: props.exportRef.current,
          exportName: props.exportName,
          extraClassNames: props.onExportExtraClassnames,
          backgroundColor: props.exportBackgroundColor,
          styleSheet: props.exportStyleSheet,
        });
      }}
    >
      Exportar
    </MobileLikeIconButton>
  );
};

export default ExportButton;
