import React, { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import Skeleton from 'react-loading-skeleton';
import { CardNumber } from '@clarke-energia/foton-v2';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';
import { buildCardNumberProps } from './helper';
import { EconomyReportIconCardData } from '@pages/economy-reports/types';
import style from './style.module.css';

export interface EconomyCardsProps extends HTMLAttributes<HTMLDivElement> {
  economyReportIconCardData: EconomyReportIconCardData;
  loading: boolean;
  handleRedirect: (path: string) => void;
}

const AccumulatedEconomyCards: React.FC<EconomyCardsProps> = ({
  economyReportIconCardData,
  loading,
  handleRedirect,
  ...props
}) => {
  const handleEventTracker = (cardTitle: string) => {
    if (cardTitle === 'Economia do último mês') {
      eventTracker.trackEvent(eventLabels.CARD_ECONOMY_DASHBOARD);
      return;
    }
    if (cardTitle === 'Custo do último mês') {
      eventTracker.trackEvent(eventLabels.CARD_COST_DASHBOARD);
      return;
    }
    eventTracker.trackEvent(eventLabels.CARD_CONSUMPTION_DASHBOARD);
  };

  return (
    <div {...props} className={style.cardsContainer}>
      {buildCardNumberProps(economyReportIconCardData).map((item, index) => {
        return loading ? (
          <Skeleton height={200} key={`iconCard-${index}`} />
        ) : (
          <button onClick={() => handleRedirect(item.redirectLink)}>
            <CardNumber
              buttonIcon={{
                icon: 'EyeIcon',
                kind: 'primary',
                size: 'small',
                onClick: () => {
                  handleEventTracker(item.title);
                  handleRedirect(item.redirectLink);
                },
                className: 'bg-primary-30',
              }}
              key={`iconCard-${index}`}
              title={item.title}
              value={item.value ?? '-'}
              variant={item.variant}
              icon={item.icon}
              ratio={item.ratio}
              tooltip={item.tooltip}
              className={twMerge(
                'shadow-md bg-[#FAFAFA]',
                index === 2 && 'bg-opacity-100 md:bg-opacity-80',
                index === 0 && 'bg-opacity-[82%] md:bg-opacity-100',
              )}
            />
          </button>
        );
      })}
    </div>
  );
};

export default AccumulatedEconomyCards;
