export const eventLabels = {
  BUTTON_CLICKED: 'Button Clicked',
  PAGE_VIEWED: 'Page Viewed',
  BUTTON_EXPAND_MENU: 'button_expand_menu',
  BUTTON_LEAVE_MENU: 'button_leave_menu',
  BUTTON_CHANGE_COMPANY_MENU: 'button_changecompany_menu',
  PAGE_VIEW_DASHBOARD: 'page_view_dashboard',
  CARD_ECONOMY_DASHBOARD: 'card_economy_dashboard',
  CARD_COST_DASHBOARD: 'card_cost_dashboard',
  CARD_CONSUMPTION_DASHBOARD: 'card_consumption_dashboard',
  CHART_ECONOMY_DASHBOARD: 'chart_economy_dashboard',
  CHART_COST_DASHBOARD: 'chart_cost_dashboard',
  BUTTON_EXPORT_DASHBOARD: 'button_export_dashboard',
  PAGE_VIEW_ECONOMY_REPORT: 'page_view_economyreport',
  PAGE_VIEW_ECONOMY_REPORT_UC: 'page_view_economyreportuc',
  PAGE_VIEW_ECONOMY_REPORT_UC_MONTH: 'page_view_economyreportucmonth',
  BUTTON_FILTER_UC_ECONOMY_REPORT: 'button_filter_uc_economyreport',
  BUTTON_FILTER_DATE_ECONOMY_REPORT: 'button_filter_date_economyreport',
  BUTTON_EXPORT_ECONOMY_REPORT: 'button_export_economyreport',
  BUTTON_EXPORT_CHART_ECONOMY_ECONOMY_REPORT_UC: 'button_export_chart_economy_economyreportuc',
  BUTTON_EXPORT_CHART_ECONOMY_ECONOMY_REPORT: 'button_export_chart_economy_economyreport',
  BUTTON_EXPORT_CHART_COST_ECONOMY_REPORT: 'button_export_chart_cost_economyreport',
  BUTTON_EXPORT_CHART_COST_ECONOMY_REPORT_UC: 'button_export_chart_cost_economyreportuc',
  BUTTON_FILTER_DATE_ECONOMY_REPORT_UC: 'button_filter_date_economyreportuc',
  BUTTON_SEE_MORE_UC: 'button_seemore_uc_economyreport',
  ACCORDION_COMPARISON_ECONOMY_REPORT_MONTH: 'accordion_comparison_economyreportucmonth',
  BUTTON_EXPORT_COMPARISON_ECONOMY_REPORT_UC_MONTH: 'button_export_comparison_economyreportucmonth',
  PAGE_VIEW_MIGRATION: 'page_view_migration',
  PAGE_VIEW_MIGRATION_DETAILS: 'page_view_migrationdetails',
  BUTTON_EXPORT_MIGRATION: 'button_export_migration',
  CARD_FINISHED_MIGRATION: 'card_finished_migration',
  CARD_IN_PROGRESS_MIGRATION: 'card_inprogress_migration',
  CARD_DELAYED_MIGRATION: 'card_delayed_migration',
  CARD_SEEMORE_UC_MIGRATION: 'card_seemore_uc_migration',
  CARD_DOCUMENTS_MIGRATION: 'card_documents_migrationdetails',
  CARD_CONTRACTED_DEMAND_MIGRATION_DETAILS: 'card_contracteddemand_migrationdetails',
  CARD_DISTRIBUTOR_MIGRATION_DETAILS: 'card_distributor_migrationdetails',
  CARD_SMF_MIGRATION_DETAILS: 'card_smf_migrationdetails',
  CARD_MODELING_MIGRATION_DETAILS: 'card_modeling_migrationdetails',
  BUTTON_FILTER_MIGRATION_DETAILS: 'button_filter_migrationdetails',
  PAGE_VIEW_CONSUMPTION: 'page_view_consumption',
  PAGE_VIEW_CONSUMPTION_UC: 'page_view_consumptionuc',
  PAGE_VIEW_CONSUMPTION_UC_MONTH: 'page_view_consumptionucmonth',
  PAGE_VIEW_CONSUMPTION_MONTH: 'page_view_consumptionmonth',
  BUTTON_FILTER_UC_CONSUMPTION: 'button_filter_uc_consumption',
  BUTTON_FILTER_DATE_CONSUMPTION: 'button_filter_date_consumption',
  BUTTON_EXPORT_CONSUMPTION: 'button_export_consumption',
  BUTTON_EXPORT_CHART_HISTORY_CONSUMPTION: 'button_export_chart_history_consumption',
  BUTTON_EXPORT_CHART_HISTORY_CONSUMPTION_UC: 'button_export_chart_historyuc_consumption',
  BUTTON_FILTER_INFO_BY_UC_CONSUMPTION: 'button_filter_infobyuc_consumption',
  BUTTON_EXPORT_CHART_HISTORY_CONSUMPTION_PER_UC: 'button_export_chart_history_consumptionuc',
  BUTTON_CHART_EXPORT_CONSUMPTION_BY_DAY_CONSUMPTION_MONTH: 'button_export_chart_consumptionbyday_consumptionmonth',
  BUTTON_EXPORT_CHART_CONSUMPTION_BY_HOUR_CONSUMPTION_MONTH: 'button_export_chart_consumptionbyhour_consumptionmonth',
  BUTTON_DATE_FILTER_CHART_CONSUMPTION_BY_HOUR_CONSUMPTION_MONTH:
    'button_datefilter_chart_consumptionbyhour_consumptionmonth',
  BUTTON_EXPORT_CHART_CONSUMPTION_BY_DAY_CONSUMPTION_UC_MONTH:
    'button_export_chart_consumptionbyday_consumptionucmonth',
  BUTTON_EXPORT_CHART_CONSUMPTION_BY_HOUR_CONSUMPTION_UC_MONTH:
    'button_export_chart_consumptionbyhour_consumptionucmonth',
  BUTTON_DATE_FILTER_CHART_CONSUMPTION_BY_HOUR_CONSUMPTION_UC_MONTH:
    'button_datefilter_chart_consumptionbyhour_consumptionucmonth',
  PAGE_VIEW_SUSTENTABILITY: 'page_view_sustentability',
  BUTTON_EXPORT_SUSTENTABILITY: 'button_export_sustentability',
  BUTTON_DISABLED_IREC_SUSTENTABILITY: 'disabled_button_irec_sustentability',
  BUTTON_DISABLED_CERTIFICATE_SUSTENTABILITY: 'disabled_button_certificate_sustentability',
  BUTTON_BIOMASSA: 'button_biomassa_sustentability',
  BUTTON_SOLAR: 'button_solar_sustentability',
  BUTTON_EOLICA: 'button_eolica_sustentability',
  BUTTON_PCH: 'button_pch_sustentability',
  PAGE_VIEW_CONTRACTS: 'page_view_contracts',
  TOGGLE_VOLUME_CONTRACTS: 'toggle_volume_contracts',
  BUTTON_FILTER_CONTRACTS: 'button_filter_contracts',
  BUTTON_SEE_MORE_CONTRACTS: 'button_seemore_contracts',
};
