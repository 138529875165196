import React from 'react';
import { IconButtonProps, TooltipContentProps } from '@clarke-energia/foton-v2';
import { twMerge } from 'tailwind-merge';

import { useDownloadFiles } from '@hooks/download-files';
import CardComponent from '@components/molecules/card';
import { Contract } from '@hooks/get-contracts/types';

import style from './style.module.css';

type ContractCard = Pick<Contract, 'type' | 'active' | 'unitsNames' | 'period' | 'contracted' | 'file' | 'isStandby'>;

export interface CardSectionProps extends ContractCard {
  onViewClick: () => void;
}

const buttonsHeader = (file: CardSectionProps['file'], onViewClick: () => void) => {
  const iconButtons: IconButtonProps[] = [{ kind: 'secondary', icon: 'EyeIcon', size: 'small', onClick: onViewClick }];
  const { directDownload } = useDownloadFiles();

  if (file) {
    const downloadBtn: IconButtonProps = {
      kind: 'secondary',
      icon: 'ArrowDownTrayIcon',
      size: 'small',
      onClick: () => directDownload(file.link, file.name),
    };
    iconButtons.push(downloadBtn);
  }

  return iconButtons;
};

const ContractCardsSection: React.FC<CardSectionProps> = ({
  type,
  active,
  isStandby,
  contracted,
  period,
  unitsNames,
  file,
  onViewClick,
}) => {
  const isEnergyCard = type === 'ENERGY';
  const cardStyle = isEnergyCard ? style.cardEnergyActive : style.cardManagementActive;
  const cardActiveStyle = active && !isStandby ? cardStyle : style.cardInactive;

  const handleToolTip = (): TooltipContentProps => {
    if (active && !isStandby) {
      return {
        icon: {
          icon: 'CheckBadgeIcon',
          extraClassNames: twMerge(style.iconCard, cardActiveStyle),
          solid: true,
        },
        content: 'Contrato ativo',
        side: 'top',
      };
    }

    if (isStandby) {
      return {
        icon: {
          icon: 'CheckBadgeIcon',
          extraClassNames: twMerge(style.iconCard, cardActiveStyle),
          solid: true,
        },
        content: 'Contrato futuro',
        side: 'top',
      };
    }

    return {
      icon: {
        icon: 'CheckBadgeIcon',
        extraClassNames: twMerge(style.iconCard, style.cardInactive),
        solid: true,
      },
      content: 'Contrato inativo',
      side: 'top',
    };
  };

  const handleStatusContent = (): 'Ativo' | 'Inativo' | 'Contrato futuro' => {
    if (active && !isStandby) {
      return 'Ativo';
    }

    if (isStandby) {
      return 'Contrato futuro';
    }

    return 'Inativo';
  };

  return (
    <div className={style.cardContainer}>
      <CardComponent
        title={`Contrato de ${isEnergyCard ? 'energia' : 'gestão'}`}
        tooltip={handleToolTip()}
        headerButtons={buttonsHeader(file, onViewClick)}
      >
        <p className={style.cardText}>{`Contratada: ${isEnergyCard ? contracted : 'Clarke'}`}</p>
        <p className={style.cardText}>{`Vigência: ${period}`}</p>
        <p className={style.cardText}>{`Unidades Consumidoras: ${
          unitsNames.length === 2 ? unitsNames.join(' e ') : unitsNames.join(', ')
        }`}</p>
        <p className={style.cardText}>{`Status: ${handleStatusContent()}`}</p>
      </CardComponent>
    </div>
  );
};

export default ContractCardsSection;
