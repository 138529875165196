import { useEffect, useMemo, useState } from 'react';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';

import HourConsumptionChart from '@components/organisms/consumption/hour-consumption-chart';
import DailyConsumptionChart from '@components/organisms/consumption/daily-consumption-chart';
import DonutConsumptionByUnitChart from '@components/organisms/consumption/donut-consumption-by-unit-chart';

import configuration from '@config';
import { formatDateFromString } from '@utils/dayjs';
import { GroupDatetimeMeasurement } from '@contexts/consumption';

import { useUserInfo } from '@hooks/use-user-info';
import { useConsumption } from '@hooks/use-consumption';
import { useUnitsConsumptionsByGroup } from '@hooks/consumption/use-units-consumptions-by-group';
import { useGetGroupAccumulatedEconomyReport } from '@hooks/economy/get-group-accumulated-economy-report';

import { parseMeasureDate } from '../helper';

import { calculateRelativePercent, getConsumptionsParamsByMonth } from './helpers';
import ConsumptionsCards from './consumptions-cards';

import style from './style.module.css';

const parseProjection = (value?: string | null) => {
  return value ? parseFloat(value) : null;
};

const MonthlyConsumptionByGroup = () => {
  const {
    monthlyGroupControlMeasurement,
    consumptionProjectionByGroup,
    monthYear,
    groupConsolidatedMeasurementById,
    setGroupConsolidatedMeasurementDocId,
  } = useConsumption();

  if (!monthYear) return null;

  const { consumptions: consumptionsByUnit } = useUnitsConsumptionsByGroup();

  const { user } = useUserInfo();

  const {
    getGroupAccumulatedEconomyReportByGroupId,
    parsedData: { rawSummaryEconomyReportsHistory },
  } = useGetGroupAccumulatedEconomyReport();

  const [month, year] = monthYear.split('/');

  const [selectedDay, setSelectedDay] = useState('');

  if (monthlyGroupControlMeasurement?.length && !selectedDay) {
    setSelectedDay(parseMeasureDate(monthlyGroupControlMeasurement[0].date));
  }

  const isActualMonth = useMemo(() => {
    const today = new Date();
    return today.getFullYear() === parseInt(year) && today.getMonth() + 1 === parseInt(month);
  }, [monthYear]);

  useEffect(() => {
    if (user?.group?.id) {
      getGroupAccumulatedEconomyReportByGroupId({
        variables: {
          groupId: user.group.id,
        },
      });
    }
  }, [user]);

  const groupId = useMemo(() => {
    return user.group?.id;
  }, [user]);

  // This useEffect is necessary because the context does not complete loading before setGroupConsolidatedMeasurementDocId
  useEffect(() => {
    if (monthlyGroupControlMeasurement && !Object.keys(groupConsolidatedMeasurementById || {}).length) {
      if (!monthlyGroupControlMeasurement.length) return;

      setGroupConsolidatedMeasurementDocId(monthlyGroupControlMeasurement[0].groupConsolidatedMeasurementDocId);
    }
  }, []);

  const groupConsumptionExcelBasePath = useMemo(() => {
    return `${configuration.CCEE_API_HOST}/api/v1/group_consumption_excel/${groupId}`;
  }, [groupId]);

  const groupConsumptionExcelDaily = useMemo(() => {
    return groupConsumptionExcelBasePath + `/daily/${year}-${month}`;
  }, [groupConsumptionExcelBasePath, monthYear]);

  const groupConsumptionExcelHourly = useMemo(() => {
    return groupConsumptionExcelBasePath + `/hourly/${formatDateFromString(selectedDay, 'DD/MM/YYYY', 'YYYY-MM-DD')}`;
  }, [selectedDay]);

  return (
    <div className={style.container}>
      <div className={style.consumptionCards}>
        <ConsumptionsCards
          accumulatedConsumptionsParams={
            !isActualMonth ? getConsumptionsParamsByMonth(rawSummaryEconomyReportsHistory || [], monthYear) : undefined
          }
          projections={
            isActualMonth
              ? {
                  consumption: {
                    value: parseProjection(consumptionProjectionByGroup?.monthlyConsumptionProjection),
                    percent: consumptionProjectionByGroup?.monthlyConsumptionProjection
                      ? calculateRelativePercent({
                          monthYear,
                          groupAccumulatedEconomyReports: rawSummaryEconomyReportsHistory || [],
                          value: parseProjection(consumptionProjectionByGroup?.monthlyConsumptionProjection),
                          getValue: (economyReport) => economyReport.monthlyEnergyConsumption,
                        })
                      : null,
                  },
                }
              : undefined
          }
        />
      </div>
      <div className={style.dailyChart}>
        <DailyConsumptionChart
          eventTrackerLabel="BUTTON_CHART_EXPORT_CONSUMPTION_BY_DAY_CONSUMPTION_MONTH"
          title="Consumo por dia"
          measures={monthlyGroupControlMeasurement || []}
          excelPath={groupConsumptionExcelDaily}
          exportName={'grafico-de-consumo-diario-mensal-' + monthYear.replace('/', '-')}
          projectedMeasures={consumptionProjectionByGroup?.dailyConsumptionProjection || []}
        />
      </div>
      <div className={style.hourCharts}>
        <HourConsumptionChart
          eventTrackerLabel="BUTTON_EXPORT_CHART_CONSUMPTION_BY_HOUR_CONSUMPTION_MONTH"
          title="Consumo por hora"
          exportName="consumo-por-hora-do-grupo"
          days={monthlyGroupControlMeasurement?.map((measure) => parseMeasureDate(measure.date)) || []}
          defaultDay={selectedDay}
          onChangeDay={(day) => {
            setSelectedDay(day);
            eventTracker.trackEvent(eventLabels.BUTTON_DATE_FILTER_CHART_CONSUMPTION_BY_HOUR_CONSUMPTION_MONTH);
            setGroupConsolidatedMeasurementDocId(
              monthlyGroupControlMeasurement?.find((measure) => parseMeasureDate(measure.date) === day)
                ?.groupConsolidatedMeasurementDocId,
            );
          }}
          consolidatedMeasures={
            groupConsolidatedMeasurementById?.datetimeMeasurement || ([] as GroupDatetimeMeasurement[])
          }
          excelPath={groupConsumptionExcelHourly}
        />
      </div>
      <div className={style.donutChart}>
        <DonutConsumptionByUnitChart
          title="Consumo por UC"
          consumptions={Object.values(consumptionsByUnit || {})}
          monthYear={monthYear}
        />
      </div>
    </div>
  );
};

export default MonthlyConsumptionByGroup;
