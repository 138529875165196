import React, { useMemo } from 'react';
import { LineChart } from '@clarke-energia/foton-v2';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';

import Layout from '@components/templates/screen-layout';
import { HubSkeleton } from '@components/molecules/skeleton/hub-skeleton';
import SustainabilityCertificates from '@components/organisms/sustainability/sustainability-certificates';
import SustainabilityNuggets from '@components/organisms/sustainability/sustainability-nuggets';
import EmptyData from '@components/molecules/empty-data';
import ExportButton from '@components/molecules/button/export-button';

import { useContractByGroup } from '@hooks/get-contracts/use-contracts-group';
import { useGetGroupAccumulatedEconomyReport } from '@hooks/economy/get-group-accumulated-economy-report';
import { useIsMobile } from '@hooks/is-mobile';
import { useUserInfo } from '@hooks/use-user-info';
import { parseSustainabilityReportCharts } from '@hooks/economy/parser';

import { HOME_PATH, SUSTAINABILITY_PATH } from '@routers/constants';

import style from './style.module.css';

const SustainabilityHub: React.FC = () => {
  const exportRef = React.useRef<HTMLDivElement>(null);
  const { user, isFetchingUser } = useUserInfo();

  const {
    getGroupAccumulatedEconomyReportByGroupId,
    getGroupAccumulatedEconomyReportByUnitId,
    data,
    parsedData,
    loading,
  } = useGetGroupAccumulatedEconomyReport();

  const {
    getContractByGroup,
    data: { mainEnergyType },
  } = useContractByGroup();

  const isMobile = useIsMobile();

  const pageTitle = isMobile ? 'Sustentabilidade' : 'Relatório de Sustentabilidade';

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.PAGE_VIEW_SUSTENTABILITY);
  }, []);

  useMemo(() => {
    if (user?.group) {
      getContractByGroup({
        variables: {
          groupId: user.group.id,
        },
      });

      if (user.group.units.length === 1) {
        const unitId = user.group.units[0].id;

        getGroupAccumulatedEconomyReportByUnitId({
          variables: {
            unitId: unitId,
          },
        });
      } else {
        getGroupAccumulatedEconomyReportByGroupId({
          variables: {
            groupId: user.group.id,
          },
        });
      }
    }
  }, [user]);

  const summaryEconomyHistoryCharts = useMemo(() => {
    return parseSustainabilityReportCharts(parsedData?.rawSummaryEconomyReportsHistory ?? []);
  }, [parsedData]);

  if (isFetchingUser || loading) {
    return (
      <Layout.Content
        header={{
          title: '',
          displayDesktop: true,
        }}
      >
        <HubSkeleton />
      </Layout.Content>
    );
  }

  if (!data) {
    return (
      <Layout.Content
        extraContentClassName="h-screen"
        header={{
          title: pageTitle,
          displayDesktop: true,
          breadCrumbLinks: [
            {
              label: 'Home',
              url: HOME_PATH,
            },
            {
              label: 'Relatório de Sustentabilidade',
              url: SUSTAINABILITY_PATH,
            },
          ],
        }}
      >
        <EmptyData description="A migração para o Mercado Livre ainda não foi concluída. Você encontrará os relatórios de sustentabilidade assim que concluir o processo de migração" />
      </Layout.Content>
    );
  }

  return (
    <Layout.Content
      header={{
        title: pageTitle,
        displayDesktop: true,
        breadCrumbLinks: [
          {
            label: 'Home',
            url: HOME_PATH,
          },
          {
            label: 'Relatório de Sustentabilidade',
            url: SUSTAINABILITY_PATH,
          },
        ],
        children: (
          <ExportButton
            exportRef={exportRef}
            exportName="relatorio-de-sustentabilidade"
            className="mr-2"
            eventTrackerLabel="BUTTON_EXPORT_SUSTENTABILITY"
          />
        ),
      }}
    >
      <div className={style.pageContainer} ref={exportRef}>
        <div className={style.nuggetsContainer}>
          <SustainabilityNuggets
            totalCo2Avoided={
              parsedData.parsedGroupAccumulatedEconomyReport?.totalCarbonDioxideEmissionAvoided ??
              'Informação Indisponível'
            }
            totalTreesSaved={
              parsedData.parsedGroupAccumulatedEconomyReport?.totalTreesSaved ?? 'Informação indisponível'
            }
            energyType={mainEnergyType}
          />
        </div>

        <div className={style.chartContainer}>
          <div>
            <LineChart
              title="Toneladas de CO2 evitadas"
              labels={summaryEconomyHistoryCharts?.CO2AvoidedHistory?.labels ?? []}
              series={[
                {
                  name: 'Toneladas de CO² evitadas',
                  // TODO: add in foton-v2 a possibility of null values
                  values: summaryEconomyHistoryCharts?.CO2AvoidedHistory?.values?.map((value) => value ?? 0) ?? [],
                  color: '#96E9B6',
                  width: isMobile ? 4 : 6,
                },
              ]}
              lines={[]}
              options={{
                legend: { show: true },
                autoScale: true,
              }}
            />
          </div>
        </div>

        <div className={style.certificatesContainer}>
          <SustainabilityCertificates />
        </div>
      </div>
    </Layout.Content>
  );
};

export default SustainabilityHub;
