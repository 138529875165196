import React from 'react';

import { BarChart, BarChartProps } from '@clarke-energia/foton-v2';

import { formatMonthAndYearDate } from '@utils/date';
import ChartContainer from '@components/molecules/charts/chart-container';

const handleChartData = (
  data: ConsumptionDemandChartProps['demands'],
  contractedDemand: number,
): Omit<BarChartProps, 'lines' | 'options' | 'title'> => {
  const lastYearData = data.slice(-12);
  const labels = lastYearData.map((demand) => formatMonthAndYearDate(demand.date));
  const series: BarChartProps['series'] = [];
  const lineSeries: BarChartProps['lineSeries'] = [
    {
      name: 'Demanda Contratada',
      data: lastYearData.map(() => contractedDemand),
      color: '#80ACFF',
      lineStyle: { type: 'solid', width: 3 },
    },
    {
      name: 'Limite',
      data: lastYearData.map(() => contractedDemand * 1.05),
      color: '#FFBC14',
      lineStyle: { type: 'solid', width: 3 },
    },
  ];

  series.push({
    name: 'Demanda Lida',
    color: '#96E9B6',
    data: lastYearData.map((demand) => demand.value),
  });

  return {
    labels,
    series,
    lineSeries,
  };
};

export interface ConsumptionDemandChartProps {
  title: string;
  contractedDemand: number;
  demands: {
    date: string;
    value: number;
  }[];
}

const ConsumptionDemandChart: React.FC<ConsumptionDemandChartProps> = ({ title, ...props }) => {
  const { labels, series, lineSeries } = handleChartData(props.demands, props.contractedDemand);

  return (
    <ChartContainer
      title={{
        text: title,
      }}
      className=""
      tooltipContent="Comparação entre a demanda lida e a demanda contratada."
    >
      <BarChart
        labels={labels}
        series={series}
        lineSeries={lineSeries}
        options={{
          legend: { show: true, bottom: 0 },
          autoScale: true,
          suffix: ' kW',
        }}
      />
    </ChartContainer>
  );
};

export default ConsumptionDemandChart;
