import { Button } from '@clarke-energia/foton-v2';
import ecologicalSustainability from '@assets/ecological-sustainability.jpeg';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';

import NuggetInfo from './large-nugget';
import { sustainabilityNuggets, certificateButtons } from './helper';
import style from './style.module.css';

const SustainabilityCertificates: React.FC = () => {
  return (
    <>
      <div className={style.imageContainer}>
        <img className={style.image} src={ecologicalSustainability} />
      </div>

      <div className={style.contentContainer}>
        <p>
          Você faz parte de <strong>30%</strong> das empresas no mercado livre que consomem energia de fontes
          renováveis!
        </p>
        <p>
          As fontes de energia não renováveis impactam diretamente o meio ambiente com a emissão de gases do efeito
          estufa. Com a Clarke Energia, consumir energia no mercado livre significa garantir que seu consumo seja
          proveniente de fontes renováveis.
          <br /> Veja quais são elas:
        </p>

        <div className={style.nuggetsContainer}>
          {/* TODO: In foton-v2, nugget just with info (centr.) */}
          {sustainabilityNuggets.map(({ icon, info, redirectLink, event }) => (
            <a
              href={redirectLink}
              key={info}
              target="_blank"
              rel="noreferrer"
              onClick={() => eventTracker.trackEvent(eventLabels[event])}
            >
              <NuggetInfo
                icon={{
                  icon,
                  extraClassNames: style.icon,
                }}
                info={info}
              />
            </a>
          ))}
        </div>

        <div className={style.buttonsContainer}>
          {certificateButtons.map(({ label, icon, event, type }) => (
            <div className="relative" key={label}>
              <Button kind={type} size="big" leftIcon={icon} className="grow" disabled>
                {label}
              </Button>
              <span
                className="absolute inset-0 cursor-not-allowed"
                onClick={() => eventTracker.trackEvent(eventLabels[event])}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SustainabilityCertificates;
