import { gql } from '@apollo/client';

const MANAGEMENT_CONTRACT = gql`
  fragment ManagementFields on ClarkeContract {
    clarkeMagmentAmount
    isActive
    readjustmentBaseDate
    readjustmentFrequency
    readjustmentIndex
    signatureDate
    attachments {
      url
      filename
    }
  }
`;

const ENERGY_CONTRACT = gql`
  fragment EnergyContractFields on EnergyContract {
    isActive
    lowerFlexibility
    upperFlexibility
    startSupplyDate
    endSupplyDate
    energyType
    guaranteeType
    otherGuaranteeType
    contractType
    readjustmentBaseDate
    readjustmentIndex
    contractedVolumePerYear {
      year
      volumeAmount
      month
    }
    economy {
      year
      amount
    }
    energyContractAttachments {
      url
      filename
    }
  }
`;

export const GET_CONTRACTS_BY_GROUP = gql`
  ${ENERGY_CONTRACT}
  ${MANAGEMENT_CONTRACT}
  query getGroupContracts($groupId: ID!) {
    getGroupContracts(groupId: $groupId) {
      units {
        id
      }
      energyContracts {
        ...EnergyContractFields
        trader {
          name
        }
        units {
          name
          id
        }
      }
      clarkeContracts {
        ...ManagementFields
        units {
          name
        }
      }
    }
  }
`;
