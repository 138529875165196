import { CardNumberProps } from '@clarke-energia/foton-v2';

import $numeral from '@utils/numeral';
import { ITotalMonthlyConsumption } from '@contexts/consumption';

import { UnitEconomyReport } from '@hooks/economy/get-units-reports/types';
import { getRatio } from '@utils/math';

import { formatCurrency } from '@utils/text';

export const getUnitHourlyConsumption = () => {
  const hourlyConsumption: {
    labels: string[];
    values: number[];
  } = {
    labels: [],
    values: [],
  };

  for (let i = 0; i < 24; i++) {
    hourlyConsumption.labels.push(i + '');
    hourlyConsumption.values.push(Math.random() * 100);
  }
  return hourlyConsumption;
};

export const getUnitDailyConsumption = () => {
  const dailyConsumption: {
    labels: string[];
    values: {
      projected: number[];
      consolidated: number[];
    };
  } = {
    labels: [],
    values: {
      projected: [],
      consolidated: [],
    },
  };

  for (let i = 1; i < 31; i++) {
    dailyConsumption.labels.push(i + '');

    if (i < 15) {
      dailyConsumption.values.consolidated.push(Math.random() * 100);
      dailyConsumption.values.projected.push(0);
    } else {
      dailyConsumption.values.consolidated.push(0);
      dailyConsumption.values.projected.push(Math.random() * 100);
    }
  }

  return dailyConsumption;
};

const getDirection = (percentage: number) => {
  if (percentage < 0) {
    return 'down';
  } else if (percentage > 0) {
    return 'up';
  }
  return 'none';
};

export const getMonthlyConsumptionInformation = ({
  consumption,
  report,
}: {
  consumption: {
    previous?: ITotalMonthlyConsumption;
    current?: ITotalMonthlyConsumption;
  };
  report: {
    previous?: UnitEconomyReport;
    current?: UnitEconomyReport;
  };
}): CardNumberProps[] => {
  const getAverageConsumption = (value: number) => {
    return value == -1 ? 0 : value;
  };

  const currentAverageConsumption = consumption.current
    ? getAverageConsumption(consumption.current.averageConsumption)
    : 0;

  const previousAverageConsumption = consumption.previous
    ? getAverageConsumption(consumption.previous.averageConsumption)
    : 0;

  const averageRatio = getRatio(previousAverageConsumption, currentAverageConsumption);

  const previousConsumption = consumption.previous?.consumption ?? 0;
  const currentConsumption = consumption.current?.consumption ?? 0;
  const consumptionRatio = getRatio(previousConsumption, currentConsumption);

  const previousReactives = report.previous?.freeMarket.energyDistribution.reactivesTotal ?? 0;
  const currentReactives = report.current?.freeMarket.energyDistribution.reactivesTotal ?? 0;
  const reactivesRatio = getRatio(previousReactives, currentReactives);

  const toolTip = { content: 'Em comparação ao mês anterior' };

  return [
    {
      icon: {
        name: 'LightBulbIcon',
      },
      title: 'Consumo total',
      value: `${typeof currentConsumption === 'number' ? $numeral(currentConsumption).format('0,0.000') : '-'} MWh`,
      variant: 'normal',
      ratio: currentConsumption
        ? {
            value: $numeral(consumptionRatio / 100).format('0.00%'),
            direction: getDirection(consumptionRatio),
          }
        : undefined,
      tooltip: currentConsumption ? toolTip : undefined,
    },
    {
      icon: {
        name: 'CalendarDaysIcon',
      },
      title: 'Consumo médio',
      value: `${
        typeof currentAverageConsumption === 'number' ? $numeral(currentAverageConsumption).format('0,0.000') : '-'
      } MWh`,
      variant: 'normal',
      ratio: currentAverageConsumption
        ? {
            value: $numeral(averageRatio / 100).format('0.00%'),
            direction: getDirection(averageRatio),
          }
        : undefined,
      tooltip: currentAverageConsumption ? toolTip : undefined,
    },
    {
      icon: {
        name: 'BanknotesIcon',
      },
      title: 'Reativos do mês',
      value: `${currentReactives ? formatCurrency(currentReactives) : 'R$ -'}`,
      variant: 'normal',
      ratio: currentReactives
        ? {
            value: $numeral(reactivesRatio / 100).format('0.00%'),
            direction: getDirection(reactivesRatio),
          }
        : undefined,
      tooltip: currentReactives ? toolTip : undefined,
    },
    {
      icon: {
        name: 'BoltIcon',
      },
      title: 'Demanda lida / contratada',
      value: `${report.current?.freeMarket.readDemandOffPeak ?? '-'} kW / ${
        report.current?.unit.contractedDemandOffPeak ?? '-'
      } kW`,
      variant: 'normal',
    },
  ];
};
