import dayjs from 'dayjs';
import { BarChartProps } from '@clarke-energia/foton-v2';

import { $dayjs } from '@utils/dayjs';
import { Contract } from '@hooks/get-contracts/types';
import { UnitConsumptionReportGraphQL } from './types';

const MONTH_YEAR_FORMAT = 'MM/YYYY';

export const buildPeriods = (initialMonthYear: string, finalMonthYear?: string) => {
  const initialDate = dayjs(initialMonthYear, MONTH_YEAR_FORMAT);
  const periods = [];

  if (!finalMonthYear) {
    for (let i = 0; i < 12; i++) {
      periods.push(initialDate.add(i, 'month').format(MONTH_YEAR_FORMAT));
    }
  } else {
    const finalDate = dayjs(finalMonthYear, MONTH_YEAR_FORMAT);
    const totalMonths = finalDate.diff(initialDate, 'month') + 1;

    for (let i = 0; i < totalMonths; i++) {
      periods.push(initialDate.add(i, 'month').format(MONTH_YEAR_FORMAT));
    }
  }

  return periods;
};

export const buildConsumptionChart = (
  consumptions: UnitConsumptionReportGraphQL['last12MonthsRetroactiveByUnitIds'],
  flexPerYear: Contract['flexPerYear'],
  volumePerYear: Contract['volumePerYear'],
  isStandby?: boolean,
): BarChartProps => {
  const realConsumption: number[] = [];
  const contractedConsumptions: number[] = [];
  const upperFlex: number[] = [];
  const lowerFlex: number[] = [];

  const consumptionsData = buildConsumptions(consumptions);

  const periods = Array.from(consumptionsData.keys());

  periods.forEach((period) => {
    const consumption = consumptionsData.get(period);
    const [_, year] = period.split('/');

    const volume = volumePerYear?.find(({ year: volumeYear }) => Number(year) === volumeYear);
    const flex = flexPerYear?.find(({ year: flexYear }) => Number(year) === flexYear);

    if (consumption && isStandby && volumePerYear && flexPerYear) {
      realConsumption.push(0);
      contractedConsumptions.push(volumePerYear[0].amountMWh);
      upperFlex.push(flexPerYear[0].upperFlex);
      lowerFlex.push(flexPerYear[0].lowerFlex ?? 0);
    } else if (consumption && !isStandby && volume && flex) {
      realConsumption.push(consumption.real);
      contractedConsumptions.push(volume?.amountMWh ?? 0);
      upperFlex.push(flex?.upperFlex ?? 0);
      lowerFlex.push(flex?.lowerFlex ?? 0);
    } else {
      realConsumption.push(0);
      contractedConsumptions.push(volume?.amountMWh ?? 0);
      upperFlex.push(flex?.upperFlex ?? 0);
      lowerFlex.push(flex?.lowerFlex ?? 0);
    }
  });

  return {
    labels: periods as BarChartProps['labels'],
    series: [
      {
        name: 'Consumo Real',
        data: realConsumption,
        color: '#96E9B6',
      },
    ] as BarChartProps['series'],
    lineSeries: [
      {
        name: 'Volume Contratado',
        data: contractedConsumptions,
        color: '#80ACFF',
        lineStyle: { type: 'solid', width: 3 },
      },
      {
        name: 'Flexibilidade Superior',
        data: upperFlex,
        color: '#FF6347',
        lineStyle: { type: 'solid', width: 3 },
      },
      {
        name: 'Flexibilidade Inferior',
        data: lowerFlex,
        color: '#FFBC14',
        lineStyle: { type: 'solid', width: 3 },
      },
    ],
  };
};

const buildConsumptions = (consumptions: UnitConsumptionReportGraphQL['last12MonthsRetroactiveByUnitIds']) => {
  const consumptionMap = new Map<string, { real: number }>();

  consumptions.forEach((item) => {
    const label = $dayjs(item.date).format('MM/YYYY');
    if (consumptionMap.has(label)) {
      const currentConsumption = consumptionMap.get(label);
      if (currentConsumption) {
        currentConsumption.real += item.energyTotalConsumption;
      }
    } else {
      consumptionMap.set(label, {
        real: item.energyTotalConsumption,
      });
    }
  });

  return consumptionMap;
};
