import React from 'react';

import { NavigateFunction } from 'react-router-dom';
import { Report } from '@hooks/get-last-economy-report/types';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';
import AccumulatedEconomyCards from '@components/molecules/dashboard/cards';
import SectionTextIcon from '@components/molecules/dashboard/content-bolt';
import BarCharts from './charts';
import { ECONOMY_REPORT_PATH } from '@routers/constants';

interface EconomyProps {
  loading: boolean;
  navigate: NavigateFunction;
  data: Report;
  unitId: string;
}

const Economy: React.FC<EconomyProps> = ({ loading, data, unitId, navigate }) => {
  const handleRedirect = (path: string) => {
    if (unitId) return navigate(`${path}?unitId=${unitId}&date=${encodeURIComponent(data.date)}`);

    navigate(`${path}?date=${encodeURIComponent(data.date)}`);
  };

  return (
    <>
      <SectionTextIcon
        loading={loading}
        chart={{
          loading,
          title: {
            value: 'Economia Total',
            offsetCenter: [0, '50%'],
          },
          data: data.accumulatedSaving,
        }}
        text={
          <>
            Gestão de energia <span className="font-extrabold">inteligente</span> para {''}
            <span className="font-extrabold">resultados excepcionais.</span>
          </>
        }
      />
      <AccumulatedEconomyCards
        loading={loading}
        handleRedirect={handleRedirect}
        economyReportIconCardData={{
          economy: {
            total: data.economy.total,
            percentage: data.economy.percentage,
            direction: data.economy.direction,
          },
          energy: { ...data.energy },
        }}
      />
      <BarCharts
        economyChart={{
          loading: loading,
          title: 'Economia',
          freeMarket: {
            title: 'Custo no Mercado Livre',
            value: data.totalEconomy.freeMarket,
            color: 'bg-primary-30',
          },
          conventionalMarket: {
            title: 'Custo no Mercado Cativo',
            color: 'bg-gray-10',
            value: data.totalEconomy.conventionalMarket,
          },
          economy: data.totalSaving,
          onChange() {
            eventTracker.trackEvent(eventLabels.CHART_ECONOMY_DASHBOARD);
            handleRedirect(ECONOMY_REPORT_PATH);
          },
        }}
        costChart={{
          loading: loading,
          title: 'Custo de energia  mês a mês',
          labels: data.totalCosts.labels,
          series: data.totalCosts.series,
          lines: [],
          onChange: () => {
            eventTracker.trackEvent(eventLabels.CHART_COST_DASHBOARD);
            handleRedirect(ECONOMY_REPORT_PATH);
          },
        }}
      />
    </>
  );
};

export default Economy;
