export interface Unit {
  id: string;
  name: string;
  submarket: SubmarketEnum;
  totalConsumptionVolume: number;
  unitType: UnitTypeEnum;
}

enum SubmarketEnum {
  NORTH = 'NORTH',
  NORTHEAST = 'NORTHEAST',
  SOUTH = 'SOUTH',
  SOUTHEAST = 'SOUTHEAST',
}

export enum UnitTypeEnum {
  COMMERCIAL = 'COMMERCIAL',
  RURAL = 'RURAL',
  INDUSTRIAL = 'INDUSTRIAL',
}
export interface CommercialGroup {
  id: string;
  name: string;
}

export interface CommercialGroupGraphQLData {
  groups: { data: CommercialGroup[] };
}
