import { useLazyQuery } from '@apollo/client';

import { GET_CONTRACTS_BY_GROUP } from './queries';
import { Contract, ContractGroupGraphQL, EnergyTypeEnum } from './types';
import { formatEnergyContracts, formatManagementContracts } from './parser';

export const useContractByGroup = () => {
  const [getContractByGroup, { data, loading, error }] = useLazyQuery<ContractGroupGraphQL>(GET_CONTRACTS_BY_GROUP);
  let formattedData: {
    energyContracts: Contract[];
    managementContracts: Contract[];
    mainEnergyType: keyof typeof EnergyTypeEnum | string;
  } = {
    energyContracts: [],
    managementContracts: [],
    mainEnergyType: '-',
  };

  if (data) {
    const {
      getGroupContracts: { clarkeContracts, energyContracts },
    } = data;

    const energyContractsFormatted =
      energyContracts && energyContracts.length ? formatEnergyContracts(energyContracts) : [];
    const managementeContractsFormatted =
      energyContracts && clarkeContracts.length ? formatManagementContracts(clarkeContracts) : [];

    const mainEnergyType =
      energyContracts && energyContracts.length
        ? [
            ...new Set(
              energyContracts.filter(({ isActive }) => isActive).map(({ energyType }) => EnergyTypeEnum[energyType]),
            ),
          ].join(',')
        : '-';

    formattedData = {
      energyContracts: energyContractsFormatted,
      managementContracts: managementeContractsFormatted,
      mainEnergyType: mainEnergyType,
    };
  }

  return {
    getContractByGroup,
    singleUnit: data?.getGroupContracts.units.length === 1,
    data: formattedData,
    loading,
    error,
  };
};
